<template>
	<BoxModal>
		<div>
			<h3>Are you sure?</h3>
			<p>The list will be permanently deleted</p>
		</div>
		<div>
			<button @click="$store.commit('SET_MODAL', '')">Cancel</button>
			<span></span>
			<button @click="confirmar">Confirm</button>
		</div>
	</BoxModal>
</template>

<script>
	import firebase from 'firebase';
	const BoxModal = () => import('@/components/modal/_ModalBox.vue');
	export default {
		name: 'ModalExcluirLista',
		components: {
			BoxModal,
		},
		data() {
			return {
				key: null,
			};
		},
		methods: {
			confirmar() {
				this.$store.commit('SET_ADICIONAR_LOADING');
				firebase
					.database()
					.ref('users')
					.child(firebase.auth().currentUser.uid)
					.child(this.key)
					.remove()
					.then(() => {
						this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: ':(',
							mensagem: 'Your list has been deleted',
						});
						this.$store.commit('user/SET_ATUALIZAR_LISTA', true);
						this.$store.commit('SET_MODAL', '');
					})
					.catch((err) => {
						return this.$store.commit('SET_ALERTA', {
							ativado: true,
							titulo: 'Error',
							mensagem: err.message,
						});
					})
					.finally(() => this.$store.commit('SET_REMOVER_LOADING'));
			},
		},
		mounted() {
			this.key = this.$store.state.user.keyListaExcluir;
			this.$store.commit('user/SET_KEY_LISTA_EXCLUIR', null);
		},
	};
</script>
